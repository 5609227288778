import React from "react"
import { Link } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import styled from "styled-components"

const Container = styled.div`
  h2 {
    font-size: 32px;
    line-height: 1.1;
    margin: 0 0 0.5em;
  }
`

const serializers = {
  marks: {
    link: ({ mark, children }) => {
      mark.href = mark.href ?? ""

      return mark.href.substr(0, 1) === "/" ? (
        <Link to={mark.href}>{children}</Link>
      ) : (
        <a
          href={mark.href}
          target={mark.href.substr(0, 6) === "mailto" ? "" : "_blank"}
          rel={mark.href.substr(0, 6) === "mailto" ? "" : "noopener noreferrer"}
        >
          {children}
        </a>
      )
    },
  },
}

const RichText = ({ blocks, ...props }) => (
  <Container>
    <BlockContent blocks={blocks} serializers={serializers} {...props} />
  </Container>
)

export default RichText
